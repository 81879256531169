<template>
<div class="stocks-profile" style="position: relative;">
    <div class="stocks-header" v-if="stock_header_info" style="margin-bottom: 0 !important;">
        <div class="stocks-header-main">
            <div class="fs-row widgets" style="position: relative;">
                <div class="fs-cell fs-xl-5 fs-lg-12 fs-md-12 fs-sm-12 fs-xs-12">
                    <div class="avatar-box">
                        <div class="avatar" :style="avatarFinder"></div>
                    </div>
                    <div class="header-items-p title-top">
                        <div class="line">
                            <h1 class="title">{{ stock_header_info.title }}</h1>
                        </div>
                        <div class="line header-tag" style="position: relative;overflow: unset;">
                            <div style="display: inline-block;margin-left: 10px;">
                                {{ stock_header_info.slug }} |
                                {{ stock_header_info.market_fa }}
                                -
                                {{ stock_header_info.flow.title }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fs-cell fs-xl-3 fs-lg-4 fs-md-4 fs-sm-12 fs-xs-12 show-760">
                    <div class="header-items-p subtitle-res-show">
                        {{ stock_header_info.sector.title }}   ( {{ stock_header_info.category_title }} )
                    </div>
                </div>
                <div class="fs-cell fs-xl-3 fs-lg-4 fs-md-4 fs-sm-12 fs-xs-12 hide-760">
                    <div class="header-items-p">
                        <div class="line clearfix">
                            <span class="label"><i class="square background-yello"></i>گروه: </span>
                            <h4 style="display: inline-block;">
                                <span class="value">{{ stock_header_info.sector.title }}</span>
                            </h4>
                        </div>
                        <div class="line clearfix">
                            <span class="label"><i class="square background-yello"></i>زیر گروه: </span>
                            <h4 style="display: inline-block;">
                                <span class="value">{{ stock_header_info.category_title }}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="fs-cell fs-xl-2 fs-lg-4 fs-md-4 fs-sm-6 fs-xs-12">
                    <div class="header-items-p">
                        <div class="line clearfix">
                            <span class="label"><i class="square background-yello"></i>وضعیت: </span>
                            <h4 style="display: inline-block;">
                                <span class="value" data-state="A" title="">{{ stock_header_info.state_desc}}</span>
                            </h4>
                        </div>
                        <div class="line clearfix">
                            <span class="label"><i class="square background-yello"></i>آخرین قیمت: </span>
                            <span class="value">
                                <span data-col="info.last_trade.PDrCotVal" data-val="2169">{{ stock_header_info.last_trade.PDrCotVal }}</span>
                                <span data-col="info.last_trade.last_change_percentage" :data-val="stock_header_info.last_trade.last_change_percentage">
                                    <span class="change change-down change-percentage">{{ stock_header_info.last_trade.last_change_percentage }}</span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <div class="fs-cell fs-xl-2 fs-lg-12 fs-md-6 fs-sm-6 fs-xs-12 tgju-widget-forms fix-form-top hide-1220">
                    <select class="stocks-head-select">
                        <option value="https://www.shakhesban.com/markets/all">همه بازار ها</option>
                        <option value="https://www.shakhesban.com/markets/index">شاخص ها</option>
                        <option value="https://www.shakhesban.com/markets/stock" selected="">سهام</option>
                        <option value="https://www.shakhesban.com/markets/warrant">حق تقدم</option>
                        <option value="https://www.shakhesban.com/markets/fund">صندوق ها</option>
                        <option value="https://www.shakhesban.com/markets/bond">اوراق</option>
                        <option value="https://www.shakhesban.com/markets/mortgageloan">تسهیلات مسکن</option>
                        <option value="https://www.shakhesban.com/markets/future">آتی</option>
                        <option value="https://www.shakhesban.com/markets/option">اختیار معامله</option>
                        <option value="https://www.shakhesban.com/markets/closedassets">نمادهای متوقف شده</option>
                    </select>
                </div> -->
                <div class="fs-cell fs-xl-2 fs-lg-4 fs-md-4 fs-sm-6 fs-xs-12 row-last-price">
                    <div class="header-items-p">
                        <div class="line clearfix">
                            <span class="label"><i class="square background-yello"></i>قیمت پایانی: </span>
                            <span class="value">
                                <span data-col="info.last_price.PClosing" :data-val="stock_header_info.last_price.PClosing">{{ stock_header_info.last_price.PClosing }}</span>
                                <span data-col="info.last_price.closing_change_percentage" :data-val="stock_header_info.last_price.closing_change_percentage"><span
                                        class="change change-down change-percentage">{{stock_header_info.last_price.closing_change_percentage}}</span></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="stocks-header-loading">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </div>
</div>
</template>
<style>
    .tgju-widget-content.full-padding{
        height: auto !important;
    }
    .widgets .tgju-widget.has-icon {
        overflow: hidden;
        border-radius: 0 !important;
    }
    body.profile .header-items-p .line .title {
        font-size: 18px !important;
    }
    .market-main-sub-tabs {
        padding: 12px 10px 15px;
    }
    html body.profile .col-12 {
        padding: 0 5px !important;
    }
    .widgets .dataTables_wrapper .dataTables_paginate .paginate_button {
        padding: 10px 20px;
        border-radius: 6px !important;
    }
    html body.profile .widgets .tgju-widgets-row .tgju-widgets-block {
        margin-bottom: 5px;
    }
    .chart-element {
        position: relative;
        top: -10px;
    }
    html .widgets .tgju-widget .tgju-widget-title.dot::before {
        background: #eec62a !important;
    }
    .widgets .dataTables_wrapper {
        overflow: hidden;
    }
    .widgets .tgju-widget .tables-data .dataTables_wrapper {
        padding-bottom: 20px;
    }
    .dashboard-dev.dashboard-main.sidebar-set-full.bootstrap-fix.widgets {
        padding: 0 0 10px !important;
    }
    .dashboard-dev.dashboard-main.sidebar-set-full.bootstrap-fix.widgets {
        overflow-x: hidden;
    }
</style>

<script>
export default {
    name: 'StockHeader',
    props: ['data', 'type', 'name'],
    data() {
        return {
            stock_header_info: this.data.info,
        }
    },
    mounted() {
    },
    computed: {
        // آواتار یک شاخص را پیدا می کند
        avatarFinder: function () {
            if (this.stock_header_info.avatar) {
                return  {
                    'position' : 'relative',
                    'background-image': 'url(' + this.stock_header_info.avatar + ')',
                }
            } else if ( this.stock_header_info.country_code && this.stock_header_info.country_code != 'wd'){
                return  {
                    'position' : 'relative',
                    'background-size': '140%',
                    'background-image': 'url(https://www.tgju.org/images/flags/4x3/' + this.stock_header_info.country_code + '.svg)',
                }
            }

            return  {
                'position' : 'relative',
                'background-image': 'url(https://static.tgju.org/images/no-icon.png)',
            }
        },
    },
    methods: {
        getProfileHeader() {
            this.$helpers
                .makeRequest("GET", `/stock/profile-header/${this.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_header_info = api_response.data.response.detail.body.response.info;
                        // this.stock_header_summary = api_response.data.response.detail.response.summary;
                        $('.stocks-profile').append('<link rel="stylesheet" type="text/css" href="https://static.tgju.org/components/tgju-fix/fix-custom.css">');
                        $('body').addClass('internal profile fs-grid mprofile');
                    }
                });
        },
    },
}
</script>